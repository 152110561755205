<div class="header header-bg3">
  <div class="cnt-header product-box">
    <div class="container">
      <!----- Products List ----->
      <div class="row">
        <div class="offset-md-1 col-md-10 col-sm-12">
          <div class="product-list">
            <div class="row">
              <div class="col-md-6 plist-1">
                <h1>
                  <img
                    src="assets/img/event360-logo.png"
                    alt=""
                    class="product-logo"
                  />
                </h1>
                <h3>Event management platform</h3>
                <div class="txt-box">
                  <p>
                    Event 360 is one stop event management platform empowering
                    people to seamlessly organize their events. From planning
                    and publishing the events to attendance recording, and after
                    event-feedbacks gathering within one system.
                  </p>
                </div>
                <a href="#" class="effect-btn btn-blue">LET'S VISIT</a>
              </div>
              <div class="col-md-6 plist-2">
                <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
                <img
                  *ngIf="loaded"
                  src="assets/img/product.png"
                  alt=""
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
          <div class="product-list">
            <div class="row">
              <div class="col-md-6 plist-2">
                <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
                <img
                  *ngIf="loaded"
                  src="assets/img/covid-19-1.png"
                  alt=""
                  class="img-fluid"
                />
              </div>
              <div class="col-md-6 plist-1">
                <h1>COVID-19 Infographics</h1>
                <h3>Our Contribution For Society</h3>
                <div class="txt-box">
                  <p>
                    We developed this with the intention of helping our
                    community in these trouble times by making the crucial
                    information more readily available. We are collaborating
                    with doctors and medical professionals to help spread the
                    knowledge! So, let's unite together to fight this COVID-19
                    and keep our community safe.
                  </p>
                </div>
                <a
                  href="https://covid19.xerovit.io/map"
                  class="effect-btn btn-blue"
                  target="_blank"
                  >LET'S VISIT</a
                >
              </div>
            </div>
          </div>
          <div class="product-list mb-0">
            <div class="row">
              <div class="col-md-6 plist-1">
                <h1>Loyalty & Survey App</h1>
                <h3>Coming soon</h3>
              </div>
              <div class="col-md-6 plist-2">
                <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
                <img
                  *ngIf="loaded"
                  src="assets/img/product-coming-soon.png"
                  alt=""
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
