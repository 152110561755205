<!-- <p>{{ 'welcomeMessage' | translate }}</p> -->

<!----- Head Section ----->
<!-- <app-seleton-loader *ngIf="!loaded" Cwidth="1366" Cheight="350"></app-seleton-loader> -->
<div class="header header-bg1" alt="background-image">
  <div class="cnt-header home">
    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <div class="header-txt">
            <h3>Helping businesses transform & innovate.</h3>
            <p class="mt-4">
              We combine deep industry expertise, advanced analytics
              capabilities and human-centered approaches to help our clients
              shape their business strategies and drive growth.
            </p>
          </div>
        </div>
        <div class="col-md-7">
          <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
          <img
            *ngIf="loaded"
            src="assets/img/home2.png"
            alt=""
            class="home-img img-fluid"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Second Section for tablet and mobile view -->
<div class="group-image-text-wrapper">
  <div>
    <div style="background-color: rgb(58, 133, 252);" class="group-image">
      <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
      <img
        *ngIf="loaded"
        class=""
        data-src="assets/img/technology.png"
        src="assets/img/technology.png"
      />
    </div>
    <div class="group-text-wrapper">
      <div class="group-text">
        <h3>Our Belief</h3>
        <p>
          We pride ourselves in being open to new ideas and technologies in a
          fast-changing environment and being able to balance the long-term
          vision with the current reality.
        </p>
      </div>
    </div>
  </div>

  <div>
    <div style="background-color: rgb(68, 180, 255);" class="group-image">
      <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
      <img
        *ngIf="loaded"
        class=""
        data-src="assets/img/customer.png"
        src="assets/img/customer.png"
      />
    </div>
    <div class="group-text-wrapper">
      <div class="group-text">
        <h3>Customer First Approach</h3>
        <p>
          At Xerovit, we value customers collaboration over contract
          negotiation. Ultimately, the customer and we are on the same side -
          trying to improve the businesses, to innovate, to transform and most
          importantly to get results.
        </p>
      </div>
    </div>
  </div>

  <div>
    <div style="background-color: rgb(43, 71, 163);" class="group-image">
      <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
      <img
        *ngIf="loaded"
        class=""
        data-src="assets/img/envisioning.png"
        src="assets/img/envisioning.png"
      />
    </div>
    <div class="group-text-wrapper">
      <div class="group-text">
        <h3>Envision</h3>
        <p>
          It's not about adopting new technologies, but rather, the crucial part
          is envisioning how these technology can be applied to the problem we
          are trying to solve.
        </p>
      </div>
    </div>
  </div>

  <div>
    <div style="background-color: rgb(43, 71, 163);" class="group-image">
      <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
      <img
        *ngIf="loaded"
        class=""
        data-src="assets/img/innovative.png"
        src="assets/img/innovative.png"
      />
    </div>
    <div class="group-text-wrapper">
      <div class="group-text">
        <h3>Be Disruptive. Be Bold. Be Innovative.</h3>
        <p>
          The successful exploitation of new ideas is crucial to a business
          being able to improve its processes, bring new and improved products
          and services to market, increase its efficiency and, most importantly,
          improve its profitability. Adapt to new challenges by start embracing
          changes today.
        </p>
      </div>
    </div>
  </div>
</div>

<!-- Second Section for pc view -->
<div id="text-image-scroll" class="text-image-scroll-viewer">
  <div
    id="images-wrapper"
    class="images-wrapper images-wrapper-open images-wrapper-fixed-stop"
    style="background-color: rgb(58, 133, 252);"
  >
    <div class="images image1" style="left: -1869.42px;">
      <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
      <img
        *ngIf="loaded"
        class="images-show"
        data-src="assets/img/technology.png"
        alt="OPERATIONS-1"
        src="assets/img/technology.png"
      />
    </div>
    <div id="center-image1" class="images image2" style="left: -1869.42px;">
      <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
      <img
        *ngIf="loaded"
        class="images-show"
        data-src="assets/img/customer.png"
        alt="collaborate"
        src="assets/img/customer.png"
      />
    </div>
    <div id="center-image2" class="images image3" style="left: -1869.42px;">
      <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
      <img
        *ngIf="loaded"
        class="images-show"
        data-src="assets/img/envisioning.png"
        alt="reports"
        src="assets/img/envisioning.png"
      />
    </div>
    <div class="images image4" style="left: -1869.42px;">
      <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
      <img
        *ngIf="loaded"
        style="margin-left: -80px;"
        class="images-show"
        data-src="assets/img/innovative.png"
        alt="clients"
        src="assets/img/innovative.png"
      />
    </div>
  </div>
  <div id="text-wrapper" class="text-wrapper">
    <div class="slide-text-container">
      <div class="text-wrapper-inner-wrapper">
        <div class="subheader">
          <h3>Our <span style="color: #3a85fc;">Belief</span></h3>
        </div>
        <p>
          We pride ourselves in being open to new ideas and technologies in a
          fast-changing environment and being able to balance the long-term
          vision with the current reality.
        </p>
      </div>
    </div>
    <div class="slide-text-container">
      <div class="text-wrapper-inner-wrapper">
        <div class="subheader">
          <h3>
            Customer &nbsp;<span style="color: #3a85fc;">First Approach</span>
          </h3>
        </div>
        <p>
          At Xerovit, we value customers collaboration over contract
          negotiation. Ultimately, the customer and we are on the same side -
          trying to improve the businesses, to innovate, to transform and most
          importantly to get results.
        </p>
      </div>
    </div>
    <div class="slide-text-container">
      <div class="text-wrapper-inner-wrapper">
        <div class="subheader">
          <h3><span style="color: #3a85fc;">Envision</span></h3>
        </div>
        <p>
          It's not about adopting new technologies, but rather, the crucial part
          is envisioning how these technology can be applied to the problem we
          are trying to solve.
        </p>
      </div>
    </div>

    <div class="slide-text-container">
      <div class="text-wrapper-inner-wrapper">
        <div class="subheader">
          <h3>
            Be Disruptive. Be Bold. &nbsp;<span style="color: #3a85fc;"
              ><br />Be Innovative.</span
            >
          </h3>
        </div>
        <p>
          The successful exploitation of new ideas is crucial to a business
          being able to improve its processes, bring new and improved products
          and services to market, increase its efficiency and, most importantly,
          improve its profitability. Adapt to new challenges by start embracing
          changes today.
        </p>
      </div>
    </div>
  </div>
</div>
<div class="clearfix"></div>

<!-- <div class="testimonial">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h3 class="sub-title">Clients Success</h3>
          <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
              <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="slide-card">
                  <div class="testimonials-inner">
                    <div class="deck-card-left-wrapper">
                      <div class="deck-card-back"></div>
                      <div class="deck-card-front">
                        <div class="deck-card-front-bg"></div>
                      </div>
                    </div>
                    <div class="testimonial-text-section">
                      <div class="testimonial-text">
                        <img src="https://www.forecast.app/hubfs/invalid-name.svg" class="layer">
                        <img src="assets/img/quote-left.png" class="layer">
                        <h4>Our CEO needs to know that projects are on track and teams are fully allocated. Having that
                          overview has saved us over 30% on project management tasks.</h4>
                      </div>
                      <div class="testimonial-footer">
                        <div class="testimonial-author">
                          <div class="small-line"><strong>Simon</strong> - Account Manager</div>
                          <div class="small-line">Basilicom GmbH</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="slide-card">
                  <div class="testimonials-inner">
                    <div class="deck-card-left-wrapper">
                      <div class="deck-card-back"></div>
                      <div class="deck-card-front">
                        <div class="deck-card-front-bg"></div>
                      </div>
                    </div>
                    <div class="testimonial-text-section">
                      <div class="testimonial-text">
                        <img src="assets/img/quote-left.png" class="layer">
                        <h4>Our CEO needs to know that projects are on track and teams are fully allocated. Having that
                          overview has saved us over 30% on project management tasks.</h4>
                      </div>
                      <div class="testimonial-footer">
                        <div class="testimonial-author">
                          <div class="small-line"><strong>Simon</strong> - Account Manager</div>
                          <div class="small-line">Basilicom GmbH</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="slide-card">
                  <div class="testimonials-inner">
                    <div class="deck-card-left-wrapper">
                      <div class="deck-card-back"></div>
                      <div class="deck-card-front">
                        <div class="deck-card-front-bg"></div>
                      </div>
                    </div>
                    <div class="testimonial-text-section">
                      <div class="testimonial-text">
                        <img src="assets/img/quote-left.png" class="layer">
                        <h4>Our CEO needs to know that projects are on track and teams are fully allocated. Having that
                          overview has saved us over 30% on project management tasks.</h4>
                      </div>
                      <div class="testimonial-footer">
                        <div class="testimonial-author">
                          <div class="small-line"><strong>Simon</strong> - Account Manager</div>
                          <div class="small-line">Basilicom GmbH</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

<!----- Clients Logo ----->
<div class="logo-scroll">
  <div class="container">
    <div class="row">
      <div class="col-md-12 center">
        <h3 class="sub-title">Our Clients</h3>
        <div class="scroll-txt">
          <p>
            We will continue to push our limits to deliver broader range of
            digital services and solutions to help businesses transform and
            innovate.
          </p>
        </div>
      </div>
    </div>
  </div>
  <section class="customer-logos slider">
    <!-- <div class="slide" *ngFor="let image of clientLogos">
      <img [src]="image.logo" data-toggle="modal" [data-target]="image.id" style="cursor: pointer;">
    </div> -->

    <div class="slide">
      <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
      <img
        *ngIf="loaded"
        src="assets/img/client-logo/cas.png"
        data-toggle="modal"
        data-target="#img1"
        style="cursor: pointer;"
      />
    </div>
    <div class="slide">
      <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
      <img
        *ngIf="loaded"
        src="assets/img/client-logo/crew8.png"
        data-toggle="modal"
        data-target="#img2"
        style="cursor: pointer;"
      />
    </div>
    <div class="slide">
      <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
      <img
        *ngIf="loaded"
        src="assets/img/client-logo/doornlock.png"
        data-toggle="modal"
        data-target="#img3"
        style="cursor: pointer;"
      />
    </div>
    <div class="slide">
      <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
      <img
        *ngIf="loaded"
        src="assets/img/client-logo/impacthub.png"
        data-toggle="modal"
        data-target="#img4"
        style="cursor: pointer;"
      />
    </div>
    <div class="slide">
      <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
      <img
        *ngIf="loaded"
        src="assets/img/client-logo/nomad.png"
        data-toggle="modal"
        data-target="#img5"
        style="cursor: pointer;"
      />
    </div>
    <div class="slide">
      <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
      <img
        *ngIf="loaded"
        src="assets/img/client-logo/preciselifescience.png"
        data-toggle="modal"
        data-target="#img6"
        style="cursor: pointer;"
      />
    </div>
    <div class="slide">
      <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
      <img
        *ngIf="loaded"
        src="assets/img/client-logo/rbca.png"
        data-toggle="modal"
        data-target="#img7"
        style="cursor: pointer;"
      />
    </div>
    <div class="slide">
      <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
      <img
        *ngIf="loaded"
        src="assets/img/client-logo/vsa.png"
        data-toggle="modal"
        data-target="#img8"
        style="cursor: pointer;"
      />
    </div>
  </section>
</div>

<!-- Button trigger modal -->
<!-- <img src="assets/img/client-logo/crew8.png" data-toggle="modal" data-target="#exampleModalCenter" style="height: 50px;cursor: pointer;"> -->

<!-- Modal -->
<div
  class="modal fade bd-example-modal-lg"
  id="img1"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">CAS Myanmar</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
            <img
              *ngIf="loaded"
              src="assets/img/client-logo/modal-img1.png"
              alt=""
              class="img-fluid"
            />
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <h5>CAS Myanmar</h5>
            <ul>
              <li>
                <p>
                  We committed to support young fresh graduates by delivering
                  career training & consultation to assess their own interest &
                  passion for right career path. CAS is founded in 2018 with 2
                  young entrepreneurs with the purpose of helping youth to reach
                  their career goal in a right way.
                </p>
              </li>
              <li>
                Website Link :
                <a href="https://casmyanmar.com/">casmyanmar.com</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade bd-example-modal-lg"
  id="img2"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">CREW 8</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <!-- <img src="assets/img/client-logo/modal-img2.png" alt="" class="img-fluid"> -->
            <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
            <img
              *ngIf="loaded"
              src="assets/img/client-logo/crew8.png"
              alt=""
              class="img-fluid"
            />
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <h5>Crew 8</h5>
            <ul>
              <li>
                <p>
                  Whether you’re a restaurant or bar, event organizer or
                  start-up, big or boutique hotels, hiring temp staff on the fly
                  is difficult! Luckily, Crew8 is here to change that.
                </p>
              </li>
              <!-- <li>Website Link : <a href="#"></a></li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade bd-example-modal-lg"
  id="img3"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">DOORNLOCK</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <!-- <img src="assets/img/client-logo/modal-img3.png" alt="" class="img-fluid"> -->
            <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
            <img
              *ngIf="loaded"
              src="assets/img/client-logo/doornlock.png"
              alt=""
              class="img-fluid"
            />
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <h5>Doornlock</h5>
            <ul>
              <li>
                <p>
                  provide: Eco Friendly Doors & Frames, Veneer Doors & Frames,
                  Solid Wooden Doors & Frames, Fire Rated Door, Designer Lock
                  sets & Digital lock.
                </p>
              </li>
              <!-- <li>Website Link : <a href="#"></a></li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade bd-example-modal-lg"
  id="img4"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">IMPACT HUB</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
            <img
              *ngIf="loaded"
              src="assets/img/client-logo/modal-img4.png"
              alt=""
              class="img-fluid"
            />
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <h5>Impact Hub</h5>
            <ul>
              <li>
                <p>
                  An innovation lab. A business incubator. A social enterprise
                  community center. We offer you a unique ecosystem of
                  resources, inspiration, and collaboration opportunities to
                  grow your impact.
                </p>
              </li>
              <li>
                Website Link :
                <a href="https://impacthub.net/">impacthub.net</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade bd-example-modal-lg"
  id="img5"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">NOMAD</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <!-- <img src="assets/img/client-logo/modal-img5.png" alt="" class="img-fluid"> -->
            <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
            <img
              *ngIf="loaded"
              src="assets/img/client-logo/nomad.png"
              alt=""
              class="img-fluid"
            />
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <h5>Nomad</h5>
            <ul>
              <li>
                <p>
                  Nomad Digital Consulting specializes in digital projects
                  staffed with top digital talent around the world. Access the
                  best in the industry any-time anywhere.
                </p>
              </li>
              <!-- <li>Website Link : <a href="#"></a></li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade bd-example-modal-lg"
  id="img6"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          PRECISE LIFE SCIENCE
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
            <img
              *ngIf="loaded"
              src="assets/img/client-logo/modal-img6.png"
              alt=""
              class="img-fluid"
            />
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <h5>Precise Life Science</h5>
            <ul>
              <li>
                <p>
                  Laboratory specialized on life sciences such as analytical and
                  microbiological tests for food and related products as well
                  as, occasionally provide training regarding food safety and
                  related topics.
                </p>
              </li>
              <li>
                Website Link :
                <a href="https://precise-lifescience.com/"
                  >precise-lifescience.com</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade bd-example-modal-lg"
  id="img7"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Recruitment Boot</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <!-- <img src="assets/img/client-logo/modal-img7.png" alt="" class="img-fluid"> -->
            <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
            <img
              *ngIf="loaded"
              src="assets/img/client-logo/rbca.png"
              alt=""
              class="img-fluid"
            />
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <h5>Recruitment Boot</h5>
            <ul>
              <li>
                <p>
                  Whether you're a start-up finding its feet, an ambitious SME
                  or making your mark in emerging markets, Recruitment Boost is
                  designed to suit your specific business needs and grow with
                  your company.
                </p>
              </li>
              <!-- <li>Website Link : <a href="#"></a></li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade bd-example-modal-lg"
  id="img8"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">VIVENTIS</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <!-- <img src="assets/img/client-logo/modal-img8.png" alt="" class="img-fluid"> -->
            <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
            <img
              *ngIf="loaded"
              src="assets/img/client-logo/vsa.png"
              alt=""
              class="img-fluid"
            />
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <h5>VIVENTIS</h5>
            <ul>
              <li>
                <p>
                  Viventis is a leading human capital solutions company in Asia
                  Pacific focusing its efforts on enabling candidates to pursue
                  total career well-being, supporting clients in finding the
                  right talent, and helping organizations become future-ready in
                  the age of disruption.
                </p>
              </li>
              <!-- <li>Website Link : <a href="#"></a></li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
