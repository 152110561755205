<!----- head Section ----->
<div class="header header-bg2">
  <div class="cnt-header about-box">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <h1>About Us</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-8 offset-lg-3 offset-md-2 text-center mt-3">
          <p>
            We are an international group of digital natives based in Myanmar,
            that helps businesses formulate and execute digital strategies. We
            have built a reputation for combining the strategic thinking and
            engineering capabilities with digital craftsmanship to help our
            clients through beautifully tailored digital solutions such as
            mobile applications, websites, web applications as well as social
            media marketing.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<!----- About Us Lists ----->
<div class="how-we-work">
  <div id="custom-work-main" class="custom-work-main">
    <div class="custom-work-main-left">
      <div id="work_main_1" class="custom-work-main-section">
        <div class="main-section-image">
          <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
          <img *ngIf="loaded" src="assets/img/expertise.png" />
        </div>
        <div class="main-section-text">
          <div class="section-text-inner">
            <h3>Our Expertise</h3>
            <p>
              We combine deep industry expertise, advanced analytics
              capabilities and human-centered approaches to help our clients
              shape their business strategies and drive sustainable growth.
            </p>
          </div>
        </div>
      </div>
      <div id="work_main_2" class="custom-work-main-section">
        <div class="main-section-text">
          <div class="section-text-inner">
            <h3>Founding Principle</h3>
            <p>
              We focus on endurance as the fundamental principle because we
              believe the best businesses are intrinsically aligned with the
              long-term interests of society.<br /><br />
              Economic gains hinge on respect, and companies today cannot earn
              respect unless they are committed not only to objectively
              examining the consequences of their creations but also evolving to
              have a holistically positive impact on society.
            </p>
          </div>
        </div>
        <div class="main-section-image">
          <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
          <img *ngIf="loaded" src="assets/img/principle.png" />
        </div>
      </div>
      <div id="work_main_3" class="custom-work-main-section">
        <div class="main-section-image">
          <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
          <img *ngIf="loaded" src="assets/img/team.png" />
        </div>
        <div class="main-section-text">
          <div class="section-text-inner">
            <h3>The Team</h3>
            <p>
              While a principled founding team can create a great company, an
              enduring company requires a system of leadership that is
              implemented very early in its history.<br /><br />
              This framework enables the delegation and distribution of decision
              making throughout the organization. It is rooted in people
              practices that helps the company constantly recruit, develop, and
              retain leadership talent at all levels – and helps to make
              decisions that are aligned with the company’s visions and values.
            </p>
          </div>
        </div>
      </div>
      <div id="work_main_4" class="custom-work-main-section">
        <div class="main-section-text">
          <div class="section-text-inner">
            <h3>Engagement</h3>
            <p>
              Our approach is different to most, we enter a business deal
              expecting to build relationships. <br /><br />We work very closely
              with our clients to clearly capture the exact requirements and
              then work in small iterations to continually delivery progress.
              Rather than waiting until the end to see the results, our clients
              get to see and comment on the progress of the projects we engage
              upon.
            </p>
          </div>
        </div>
        <div class="main-section-image">
          <!-- <app-seleton-loader *ngIf="!loaded"></app-seleton-loader> -->
          <img *ngIf="loaded" src="assets/img/sample.png" />
        </div>
      </div>
    </div>
    <div id="read-progress" class="read-progress">
      <span id="tooltiptext2" class="tooltiptext">One</span>
      <span id="tooltiptext3" class="tooltiptext">Two</span>
      <span id="tooltiptext4" class="tooltiptext">Three</span>
      <span id="tooltiptext5" class="tooltiptext">Four</span>
      <div
        id="read-progress-status"
        class="read-progress-status"
        style="height: 5%;"
      >
        <a>
          <svg
            id="progressStatus1"
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
          >
            <path
              fill="#3a85fc"
              fill-rule="evenodd"
              d="M5 0l4.33 2.5v5L5 10 .67 7.5v-5z"
            ></path>
          </svg>
        </a>
        <a href="#work_main_1">
          <svg
            id="progressStatus2"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 14 16"
            style="transform: translateX(-50%) scale(1.8);"
          >
            <path
              fill="#FFF"
              fill-rule="evenodd"
              stroke="#3a85fc"
              stroke-width="4"
              d="M7 3l4.33 2.5v5L7 13l-4.33-2.5v-5z"
            ></path>
          </svg>
        </a>
        <a href="#work_main_2">
          <svg
            id="progressStatus3"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 14 16"
            style="transform: translateX(-50%) scale(1);"
          >
            <path
              fill="#FFF"
              fill-rule="evenodd"
              stroke="#D2D2D2"
              stroke-width="4"
              d="M7 3l4.33 2.5v5L7 13l-4.33-2.5v-5z"
            ></path>
          </svg>
        </a>
        <a href="#work_main_3">
          <svg
            id="progressStatus4"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 14 16"
          >
            <path
              fill="#FFF"
              fill-rule="evenodd"
              stroke="#D2D2D2"
              stroke-width="4"
              d="M7 3l4.33 2.5v5L7 13l-4.33-2.5v-5z"
            ></path>
          </svg>
        </a>
        <a href="#work_main_4">
          <svg
            id="progressStatus5"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 14 16"
          >
            <path
              fill="#FFF"
              fill-rule="evenodd"
              stroke="#D2D2D2"
              stroke-width="4"
              d="M7 3l4.33 2.5v5L7 13l-4.33-2.5v-5z"
            ></path>
          </svg>
        </a>
        <a>
          <svg
            id="progressStatus6"
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
          >
            <path
              fill="#d2d2d2"
              fill-rule="evenodd"
              d="M5 0l4.33 2.5v5L5 10 .67 7.5v-5z"
            ></path>
          </svg>
        </a>
      </div>
    </div>
  </div>
</div>
