<div class="cnt-header case-studies">
  <div class="container">
    <div class="row">
      <div class="offset-md-2 col-md-8 col-sm-12">
        <div class="row">
          <div class="col-md-6">
            <h3>Tokyo University of Science</h3>
            <p>
              Dynamically synergize team building niches and client-based
              schemas. Credibly utilize leading-edge methods of empowerment
              vis-a-vis market positioning e-markets. Efficiently redefine
              enabled communities through process-centric growth strategies.
              Completely monetize corporate mindshare via leading-edge action
              items.
            </p>
          </div>
          <div class="col-md-6">
            <img src="assets/img/about.jpg" alt="" class="img-fluid" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h4>Pre-implementation Challenges</h4>
            <p>
              Before implementing the TeamSpirit solution, the human resources
              department at the Tokyo University of Science was unable to
              efficiently handle the tabulation of total working hours by all
              employees manually. The sheer quantity and complexity of hours
              clocked created a massive burden to the HR department, especially
              at the end of the month.
            </p>
            <p>
              The university explored the possibility of developing the system
              from scratch in-house, an option that would incur great cost and
              resources.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h4>The TeamSpirit Solution</h4>
            <p>
              After implementing the TeamSpirit solution, it is now easy and
              convenient for both staff and managers to monitor their work in
              real time. The payroll management for overtime work has also led
              to greater efficiency.
            </p>
            <p>
              Managers are now able to check the working hours of their
              subordinates. Staff are now more aware of how often they are doing
              overtime work, and are able to regularly check if they are
              well-rested.
            </p>
            <p>
              At TeamSpirit, cloud services are updated regularly, so that staff
              and management do not need to worry about its maintenance or
              security. This empowers the team to focus on what they do best.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h4>
              TeamSpirit: Empowering Staff To Visualize Real Time Working
              Conditions.
            </h4>
            <p>
              The Tokyo University of Science was founded during the Meiji era
              with the vision of pursuing scientific knowledge and prospering
              Japan. Undoubtedly one of the most elite universities in Japan,
              the meritocratic university has produced many competent, ethical
              and global scholars in Science.
            </p>
            <p>
              The university is known to give high autonomy to its community.
              Therefore, it is a place where there are various different ways of
              working as compared to a private enterprise.
            </p>
            <p>
              “The uniqueness of universities is that there are various work
              divisions such as faculty staff, clerical staff, and professional
              staff. As a result, their salary form is also completely different
              depending on individual terms and conditions,” says the head of
              human resources.
            </p>
            <p>
              Around 3,500 people are employed at the university. Due to the
              large scale, human resource management can be extremely tedious at
              times.
            </p>
            <p>
              “We wanted to be able to monitor the labor conditions of
              administrative staff in real time as much as possible. This was
              the catalyst for us when we considered introducing a work
              management system.”
            </p>
            <p>
              How can TeamSpirit contribute to this goal? We spoke to the Head
              of Human Resources department and the information systems
              department to find out more.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h4>Adopting A World-Class Solution</h4>
            <p>
              In order to produce original research results that can contribute
              to society, the university finds it necessary to promote active
              exchanges and joint research with researchers in Japan and
              overseas.
            </p>
            <p>
              The university frequently finds cases where students attend
              overseas conferences. Universities in Japan are also increasingly
              encouraged to actively accept international students.
            </p>
            <p>
              Therefore, the Tokyo University of Science has requested for a
              multilingual and multi-device work management system that is
              accessible anywhere.
            </p>
            <p>
              “Tokyo University of Science has a vision of becoming the World’s
              University of Science. We want to fly globally with our research
              and educational capabilities. This is why a global and world-class
              work management system is very important to us,” said the Head of
              Information Systems Department.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h4>Cloud Services Reduce Costs And Improve Resource Allocation</h4>
            <p>
              Apart from global-usage, security of the solution is also of
              utmost importance in the decision to use TeamSpirit. From this
              perspective, the cloud solution is optimal in terms of resources
              and costs for the Tokyo University of Science.
            </p>
            <p>
              “Since we are a Scientific university with a global vision, many
              of our researchers are already familiar with the VRE (Virtual
              Research Environment) platform that Salesforce has built. Because
              of their familiarity with Salesforce, it was easy to introduce
              TeamSpirit when the software was deployed across the university.
            </p>
            <p>
              In the past, we used to develop individual systems at each school
              respectively. The downside of this is that not only are there huge
              upfront costs needed for installing and developing the system,
              there is also high operational resources required to manage it.
            </p>
            <p>
              In this regard, TeamSpirit has the ideal solution as the cloud
              service is updated regularly. All we have to do is to modify the
              template to suit our needs. We don’t have to stop the service and
              do maintenance, so we don’t have to worry about stopping our daily
              activities.
            </p>
            <p>
              The overwhelming advantage of having a workforce management system
              on cloud is that we do not need to build a new technical
              environment from scratch. In fact, we can even do the installation
              within a day! This speediness leads to the effective use of
              limited development resources.”
            </p>
            <div class="row">
              <div class="offset-md-2 col-md-8">
                <img src="assets/img/about.jpg" alt="" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h4>Graphs Make Understanding Intuitive</h4>
            <p>
              Tokyo University of Science has previously managed information
              about overtime work based on paper submission. When the closing
              date comes, those application documents come to the human
              resources department all at once. Digitizing this information on
              time and properly processing salaries amongst other things can
              cause a great deal of stress at the end of every month.
            </p>
            <p>
              In particular, the Human Resource Department has to bear the
              psychological burden of dealing with sensitive information
              directly linked to salaries. They are also unable to understand
              the overall workload of each employee until all documents have
              been submitted. There is also the concern of time lag of several
              days before the data was shared with managers.
            </p>
            <p>
              In this regard, improvement was seen after the introduction of
              TeamSpirit. “The payroll for overtime work is, of course, faster.
              Not only that, now it’s possible to keep track of your work in
              real time, so that managers can check the number of hours staff
              work.
            </p>
            <p>
              The data entered can be easily viewed as graphs, making it easier
              to visually understand the situation. It’s easier to draw
              attention to the parties in the department and their managers who
              are having problems, but I feel that the way they communicate is
              different because they can show the graph.”
            </p>
            <p>
              Understanding actual working conditions is one of the most
              important aspects of maintaining a safe working environment and
              compliance. Many hope that the work situation can be “visualized”
              in real-time as much as possible.
            </p>
            <p>
              In addition, managers can now quickly detect what their
              organization’s “current way of working” looks like. If they can
              work out measures before problems arise and put them into
              practice, managers can then use these smart data to create a
              better workplace. Visualizing how staff are working then leads to
              a better working environment.
            </p>
            <p>
              Some of the staff members who are using the service say that they
              have noticed improvements to their mental health. Now, they can
              take a good rest because information about replacement holidays is
              also displayed on the calendar. As a result, some people say that
              they can remember to rest.
            </p>
            <p>
              At universities, there are many cases where people go to work on
              holidays, such as entrance ceremonies, graduation ceremonies,
              exams, and open campuses. The TeamSpirit work management systems
              makes it easier for them to confirm the number of days left on
              vacation. This encourages more people to take vacation with peace
              of mind.
            </p>
            <p>
              In addition, since taking vacation and overtime calculation such
              as half-day leave and off days are completely captured by the
              system, the need for Human Resource to manually calculate time off
              has decreased.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h4>The Importance of User Interface</h4>
            <p>
              The Tokyo University of Science started using various functions of
              TeamSpirit to solve pipeline issues, with premium customer
              support, since October 2016.
            </p>
            <p>
              The university received plenty of feedback and evaluation from the
              Human Resources Department, which is highly encouraging. “We held
              briefings internally on how to use the product. As a result,
              almost everyone now inputs their data into TeamSpirit software on
              a daily basis.”
            </p>
            <p>
              The user interface of TeamSpirit is designed to be intuitive– as
              such, even if the user were not IT-savvy, they can also use the
              software easily. It is clear that the user-friendliness of the
              system promotes regular use. “During the time of shortlisting
              software, the ease of use of the user interface was a key
              consideration, since this is a software we will be using daily,”
              recalled the head of HR Department.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
