import { Component, OnInit } from '@angular/core'

declare function ProductsEffect(): any
declare var $: any

@Component({
  selector: 'app-products-page',
  templateUrl: './products-page.component.html',
  styleUrls: ['./products-page.component.scss'],
})
export class ProductsPageComponent implements OnInit {
  constructor() {}

  //seleton-loader
  loaded = true

  ngOnInit(): void {
    //seleton-loader
    // setInterval(() => {
    //   this.loaded = true;
    // });

    window.scroll(0, 0)

    ProductsEffect()
  }
}
