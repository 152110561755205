<!----- 404 (Not Found Page) ----->
<div class="cnt-header page-404">
  <div class="container">
    <div class="row">
      <div class="offset-md-2 col-md-8">
        <img src="assets/img/404.png" alt="" class="img-fluid" />
        <p>Oops! We can't find a page you are looking for</p>
        <a href="home.html" class="effect-btn btn-yellow">Back to Home</a>
      </div>
    </div>
  </div>
</div>
