<div class="header" style="background-color: #b2f9ed;">
  <div class="menu">
    <!----- 1st nav (light-green-color) ----->
    <nav id="navbar" class="navbar navbar-expand-lg navbar-light bg-nav">
      <a class="navbar-brand pc-view inout" href="#" routerLink="/">
        <img
          src="assets/img/xerovit-logo.png"
          alt=""
          class="hover-scale-effect"
        />
      </a>
      <a class="navbar-brand sp-view" href="#" routerLink="/">
        <img src="assets/img/xerovit-logo3.png" alt="" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <ng-container *ngFor="let item of topMenu">
            <li class="nav-item">
              <a
                [routerLinkActiveOptions]="{ exact: true }"
                class="nav-link"
                [routerLink]="item.url"
                routerLinkActive="active"
                >{{ item.name }}</a
              >
            </li>
          </ng-container>

          <!-- <li class="nav-item active">
              <a class="nav-link" href="#" routerLink="/" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }">HOME <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" routerLink="/about-us" routerLinkActive="active">ABOUT US</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" routerLink="/services" routerLinkActive="active">OUR SERVICES</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" routerLink="/products" routerLinkActive="active">OUR PRODUCTS</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" routerLink="/careers" routerLinkActive="active">CAREERS</a>
            </li> -->
          <!-- <li class="nav-item">
              <a class="nav-link" href="#" routerLink="/case-studies" routerLinkActive="active">CASE STUDIES</a>
            </li> -->
          <!-- <li class="nav-item">
              <a class="nav-link" href="#" routerLink="/">PRICING</a>
            </li> -->
          <!-- <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                RESOURCES
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" href="#" routerLink="/">EVENT</a>
                <a class="dropdown-item" href="#" routerLink="/">BLOG</a>
                <a class="dropdown-item" href="#" routerLink="/">FAQ</a>
                <a class="dropdown-item" href="#" routerLink="/">HIRING JOBS</a>
              </div>
            </li> -->
        </ul>
        <div class="custom-btn my-2 my-lg-0">
          <!-- <a href="company.html" class="effect-btn btn-none">COMPANY</a> -->
          <a href="#" class="effect-btn btn-green" routerLink="/contact-us"
            >GET IN TOUCH</a
          >
        </div>
      </div>
    </nav>

    <!----- 2st nav (white-color) when scrolling ----->
    <nav id="navbar2" class="navbar navbar-expand-lg navbar-light bg-light">
      <a class="navbar-brand" href="#" routerLink="/"
        ><img src="assets/img/xerovit-logo2.png" alt=""
      /></a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent2">
        <ul class="navbar-nav mr-auto">
          <ng-container *ngFor="let item of topMenu">
            <li class="nav-item">
              <a
                [routerLinkActiveOptions]="{ exact: true }"
                class="nav-link"
                [routerLink]="item.url"
                routerLinkActive="active"
                >{{ item.name }}</a
              >
            </li>
          </ng-container>

          <!-- <li class="nav-item">
              <a class="nav-link" href="#" routerLink="/case-studies" routerLinkActive="active">CASE STUDIES</a>
            </li> -->
          <!-- <li class="nav-item">
              <a class="nav-link" href="#" routerLink="/">PRICING</a>
            </li> -->
          <!-- <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                RESOURCES
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" href="#" routerLink="/">EVENT</a>
                <a class="dropdown-item" href="#" routerLink="/">BLOG</a>
                <a class="dropdown-item" href="#" routerLink="/">FAQ</a>
                <a class="dropdown-item" href="#" routerLink="/">HIRING JOBS</a>
              </div>
            </li> -->
        </ul>
        <div class="custom-btn my-2 my-lg-0">
          <!-- <a href="company.html" class="effect-btn btn-none">COMPANY</a> -->
          <a href="#" class="effect-btn btn-green" routerLink="/contact-us"
            >GET IN TOUCH</a
          >
        </div>
      </div>
    </nav>
  </div>
</div>
