import { Component, OnInit } from '@angular/core'

declare var $: any

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  topMenu = [
    { url: '/', name: 'HOME' },
    { url: '/about-us', name: 'ABOUT US' },
    { url: '/services', name: 'OUR SERVICES' },
    { url: '/products', name: 'OUR PRODUCTS' },
    { url: '/careers', name: 'CAREERS' },
    // { url: '/case-studies', name: 'CASE STUDIES' },
  ]

  constructor() {}

  ngOnInit(): void {
    $('.navbar-collapse').click(function () {
      console.log('test')
      $('.navbar-collapse').collapse('hide')
    })
  }
}
