import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { HomePageComponent } from './home-page/home-page.component'
import { AboutPageComponent } from './about-page/about-page.component'
import { ServicesPageComponent } from './services-page/services-page.component'
import { ProductsPageComponent } from './products-page/products-page.component'
import { ContactPageComponent } from './contact-page/contact-page.component'
import { UnknownPageComponent } from './unknown-page/unknown-page.component'
import { CaseStudiesComponent } from './case-studies/case-studies.component'
import { CaseStudyDetailComponent } from './case-study-detail/case-study-detail.component'
import { CaseStudyDetail2Component } from './case-study-detail2/case-study-detail2.component'
import { CaseStudyDetail3Component } from './case-study-detail3/case-study-detail3.component'
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component'
// import { TermsPageComponent } from './terms-page/terms-page.component';
// import { PrivacyPageComponent } from './privacy-page/privacy-page.component';

const routes: Routes = [
  { path: '', component: HomePageComponent, data: { title: 'Xerovit' } },
  {
    path: 'about-us',
    component: AboutPageComponent,
    data: { title: 'Xerovit - About Us' },
  },
  {
    path: 'services',
    component: ServicesPageComponent,
    data: { title: 'Xerovit - Services' },
  },
  {
    path: 'products',
    component: ProductsPageComponent,
    data: { title: 'Xerovit - Products' },
  },
  {
    path: 'case-studies',
    component: CaseStudiesComponent,
    data: { title: 'Xerovit - Case Studies' },
  },
  {
    path: 'case-study-detail',
    component: CaseStudyDetailComponent,
    data: { title: 'Xerovit - Case Study Detail' },
  },
  {
    path: 'case-study-detail2',
    component: CaseStudyDetail2Component,
    data: { title: 'Xerovit - Case Study Detail' },
  },
  {
    path: 'case-study-detail3',
    component: CaseStudyDetail3Component,
    data: { title: 'Xerovit - Case Study Detail' },
  },
  {
    path: 'terms-conditions',
    component: TermsConditionsComponent,
    data: { title: 'Xerovit - Terms & Conditions' },
  },
  {
    path: 'careers',
    loadChildren: () =>
      import('./careers/careers.module').then((m) => m.CareersModule),
  },
  {
    path: 'contact-us',
    component: ContactPageComponent,
    data: { title: 'Xerovit - Contact Us' },
  },
  // { path: 'terms-conditions', component: TermsPageComponent },
  // { path: 'privacy-policy', component: PrivacyPageComponent },
  {
    path: '**',
    component: UnknownPageComponent,
    data: { title: 'Xerovit - 404' },
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
