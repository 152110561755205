import { BrowserModule, Title } from '@angular/platform-browser'
import { NgModule } from '@angular/core'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'

// Firebase imports
import { AngularFireModule } from '@angular/fire'
import { AngularFirestoreModule } from '@angular/fire/firestore'
import { AngularFireAuthModule } from '@angular/fire/auth'

// Environment
import { environment } from '../environments/environment'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

// Shared Module
import { SharedModule } from './shared/shared.module'

// Translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { HttpClient, HttpClientModule } from '@angular/common/http'

// seleton-loader
import { SeletonLoaderModule } from './seleton-loader/seleton-loader.module'

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http)
}

import { HomePageComponent } from './home-page/home-page.component'
import { AboutPageComponent } from './about-page/about-page.component'
import { ServicesPageComponent } from './services-page/services-page.component'
import { ProductsPageComponent } from './products-page/products-page.component'
import { ContactPageComponent } from './contact-page/contact-page.component'
import { UnknownPageComponent } from './unknown-page/unknown-page.component'
import { TermsPageComponent } from './terms-page/terms-page.component'
import { PrivacyPageComponent } from './privacy-page/privacy-page.component'

import { CaseStudiesComponent } from './case-studies/case-studies.component'
import { CaseStudyDetailComponent } from './case-study-detail/case-study-detail.component'
import { CaseStudyDetail2Component } from './case-study-detail2/case-study-detail2.component'
import { CaseStudyDetail3Component } from './case-study-detail3/case-study-detail3.component'
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component'

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    AboutPageComponent,
    ServicesPageComponent,
    ProductsPageComponent,
    ContactPageComponent,
    UnknownPageComponent,
    TermsPageComponent,
    PrivacyPageComponent,
    CaseStudiesComponent,
    CaseStudyDetailComponent,
    CaseStudyDetail2Component,
    CaseStudyDetail3Component,
    TermsConditionsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    BrowserAnimationsModule,
    SharedModule,
    SeletonLoaderModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [Title],
  bootstrap: [AppComponent],
})
export class AppModule {}
