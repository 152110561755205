<div class="header header-bg2">
  <div class="cnt-header case-studies">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <h1>Case Studies</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-8 offset-lg-3 offset-md-2 text-center mt-3">
          <p>
            Monotonectally whiteboard clicks-and-mortar niches through holistic
            metrics. Credibly revolutionize holistic services with market-driven
            best practices. Monotonectally promote team driven convergence
            without fully researched innovation. Professionally incubate optimal
            services after cross-platform information.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="studies-box">
  <div class="container">
    <div class="row">
      <div class="offset-lg-1 col-lg-10 col-md-12">
        <div class="studies-list">
          <div class="row">
            <div class="col-md-6">
              <div class="case_index_img">
                <img
                  src="assets/img/about.jpg"
                  alt="Tokyo University of Science"
                />
              </div>
            </div>
            <div class="col-md-6">
              <h1>Case Study #1</h1>
              <h3>Tokyo University of Science</h3>
              <div class="txt-box">
                <p>
                  Before implementing the TeamSpirit solution, the human
                  resources department at the Tokyo University of Science was
                  unable to efficiently handle the tabulation of total working
                  hours by all employees manually.
                </p>
              </div>
              <a
                href="#"
                class="effect-btn btn-blue"
                target="_blank"
                routerLink="/case-study-detail"
                >READ MORE</a
              >
            </div>
          </div>
        </div>
        <div class="studies-list">
          <div class="row">
            <div class="col-md-6">
              <h1>Case Study #2</h1>
              <h3>IS Partners</h3>
              <div class="txt-box">
                <p>
                  Before implementing the TeamSpirit solution, the human
                  resources department at the Tokyo University of Science was
                  unable to efficiently handle the tabulation of total working
                  hours by all employees manually.
                </p>
              </div>
              <a
                href="#"
                class="effect-btn btn-blue"
                target="_blank"
                routerLink="/case-study-detail2"
                >READ MORE</a
              >
            </div>
            <div class="col-md-6">
              <div class="case_index_img">
                <img src="assets/img/about.jpg" alt="IS Partners" />
              </div>
            </div>
          </div>
        </div>
        <div class="studies-list">
          <div class="row">
            <div class="col-md-6">
              <div class="case_index_img">
                <img src="assets/img/about.jpg" alt="Japan Asia Group" />
              </div>
            </div>
            <div class="col-md-6">
              <h1>Case Study #3</h1>
              <h3>Japan Asia Group</h3>
              <div class="txt-box">
                <p>
                  Before implementing the TeamSpirit solution, the human
                  resources department at the Tokyo University of Science was
                  unable to efficiently handle the tabulation of total working
                  hours by all employees manually.
                </p>
              </div>
              <a
                href="#"
                class="effect-btn btn-blue"
                target="_blank"
                routerLink="/case-study-detail3"
                >READ MORE</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
