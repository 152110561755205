import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { LayoutModule } from '@angular/cdk/layout';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
// import { FlexLayoutModule } from '@angular/flex-layout';
// import { ShellComponent } from './shell/shell.component';

// import { MatButtonModule } from '@angular/material/button';
// import { MatToolbarModule } from '@angular/material/toolbar';
// import { MatIconModule } from '@angular/material/icon';
// import { MatSidenavModule } from '@angular/material/sidenav';
// import { MatListModule } from '@angular/material/list';
// import { MatMenuModule } from '@angular/material/menu';
// import { MatCardModule } from '@angular/material/card';
// import { MatFormFieldModule } from '@angular/material/form-field';
// import { MatInputModule } from '@angular/material/input';
// import { MatSnackBarModule } from '@angular/material/snack-bar';
// import { MatDividerModule } from '@angular/material/divider';
// import { MatRadioModule } from '@angular/material/radio';
// import { MatCheckboxModule } from '@angular/material/checkbox';
// import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// import { MatTooltipModule } from '@angular/material/tooltip';
// import { MatTableModule } from '@angular/material/table';
// import { MatSortModule } from '@angular/material/sort';
// import { MatPaginatorModule } from '@angular/material/paginator';
// import { MatProgressBarModule } from '@angular/material/progress-bar';
// import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
// import { MatDialogModule } from '@angular/material/dialog';
// import { MatSelectModule } from "@angular/material/select";
// import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
// import { MatGridListModule } from "@angular/material/grid-list";
// import { MatStepperModule } from "@angular/material/stepper";
// import { MatTabsModule } from "@angular/material/tabs";
// import { MatBadgeModule } from "@angular/material/badge";
// import { MatDatepickerModule } from "@angular/material/datepicker";
// import { MatAutocompleteModule } from "@angular/material/autocomplete";
// import { MatNativeDateModule } from "@angular/material/core";

const components = [FooterComponent, HeaderComponent];
const modules = [
  CommonModule,
  RouterModule,
  LayoutModule,
  // FlexLayoutModule,
  // MatButtonModule,
  // FormsModule,
  // MatToolbarModule,
  // MatIconModule,
  // MatSidenavModule,
  // MatListModule,
  // MatMenuModule,
  // MatIconModule,
  // MatCardModule,
  // MatFormFieldModule,
  // MatInputModule,
  // MatSnackBarModule,
  // MatDividerModule,
  // MatRadioModule,
  // MatCheckboxModule,
  // ReactiveFormsModule,
  // MatTooltipModule,
  // MatTableModule,
  // MatSortModule,
  // MatPaginatorModule,
  // MatProgressBarModule,
  // MatBottomSheetModule,
  // MatDialogModule,
  // MatSelectModule,
  // MatProgressSpinnerModule,
  // MatGridListModule,
  // MatStepperModule,
  // MatTabsModule,
  // MatBadgeModule,
  // MatDatepickerModule,
  // MatNativeDateModule,
  // MatAutocompleteModule,
];

@NgModule({
  declarations: [...components],
  imports: [...modules],
  exports: [...components, ...modules],
})
export class SharedModule {}
