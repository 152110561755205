import { Component, OnInit, HostListener } from '@angular/core'

declare function AboutEffect(): any
declare var $: any

@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss'],
})
export class AboutPageComponent implements OnInit {
  constructor() {}

  //seleton-loader
  loaded = true

  ngOnInit(): void {
    //seleton-loader
    // setInterval(() => {
    //   this.loaded = true;
    // });

    window.scroll(0, 0)

    AboutEffect()

    $('.read-progress-status svg').hover(
      function () {
        var thisID = $(this).attr('id')
        var foundID = thisID[thisID.length - 1]
        var tooltip = '#tooltiptext' + foundID
        $(tooltip).css('visibility', 'inherit')
        $(this).css('transform', 'translateX(-50%) scale(1.8)')
        $(this).find('path').attr('stroke', '#3a85fc')
      },
      function () {
        var thisID = $(this).attr('id')
        var foundID = thisID[thisID.length - 1]
        var tooltip = '#tooltiptext' + foundID
        $(tooltip).css('visibility', 'inherit')
        $(tooltip).css('visibility', 'hidden')
        $(this).css('transform', 'translateX(-50%) scale(1)')
        $(this).find('path').attr('stroke', '#D2D2D2')
      }
    )
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    var totalMainScroll = 0
    var currentReadProgressTop = parseInt($('#read-progress').css('marginTop'))
    var lastScrollPosition = 0
    var scrolledInWorkMain = 0
    var workMainHeight = $('#custom-work-main').height() + 100
    var workMainSectionHeight = $('.custom-work-main-section').height() * 5
    var updatedProgressStatus = 0
    var windowTop = $(window).scrollTop()
    var windowBottom = $(window).scrollTop() + $(window).height()
    var mainTop = $('#custom-work-main').position().top
    var workMainTop = $('#custom-work-main').position().top
    var workMainBottom =
      $('#custom-work-main').position().top +
      $('#custom-work-main').outerHeight(true)

    if (windowTop < mainTop) {
      $('#read-progress').removeClass('fixed-read-progress')
      $('#read-progress').removeClass('fixed-read-progress-end')
    }

    if (windowBottom > workMainBottom) {
      $('#read-progress').removeClass('fixed-read-progress')
      $('#read-progress').addClass('fixed-read-progress-end')
    }

    if (windowTop > mainTop && windowBottom < workMainBottom) {
      totalMainScroll = windowTop - mainTop
      var updatedMarginTop = currentReadProgressTop + totalMainScroll
      $('#read-progress').addClass('fixed-read-progress')
    }

    if (windowBottom > mainTop) {
      scrolledInWorkMain = windowBottom - mainTop
      updatedProgressStatus = (scrolledInWorkMain / workMainSectionHeight) * 100
      var percentageString = updatedProgressStatus + '%'
      $('#read-progress-status').css('height', percentageString)
    }

    if (updatedProgressStatus < 20 && updatedProgressStatus > 0) {
      $('#progressStatus2').css('transform', 'translateX(-50%) scale(1.8)')
      $('#progressStatus2 > path').attr('stroke', '#3a85fc')
      $('#progressStatus3').css('transform', 'translateX(-50%) scale(1)')
      $('#progressStatus3 > path').attr('stroke', '#D2D2D2')
    } else if (updatedProgressStatus < 40 && updatedProgressStatus > 20) {
      $('#progressStatus2').css('transform', 'translateX(-50%) scale(1.8)')
      $('#progressStatus2 > path').attr('stroke', '#3a85fc')
      $('#progressStatus3').css('transform', 'translateX(-50%) scale(1.8)')
      $('#progressStatus3 > path').attr('stroke', '#3a85fc')
      $('#progressStatus4').css('transform', 'translateX(-50%) scale(1)')
      $('#progressStatus4 > path').attr('stroke', '#D2D2D2')
    } else if (updatedProgressStatus < 60 && updatedProgressStatus > 40) {
      $('#progressStatus2').css('transform', 'translateX(-50%) scale(1.8)')
      $('#progressStatus2 > path').attr('stroke', '#3a85fc')
      $('#progressStatus3').css('transform', 'translateX(-50%) scale(1.8)')
      $('#progressStatus3 > path').attr('stroke', '#3a85fc')
      $('#progressStatus4').css('transform', 'translateX(-50%) scale(1.8)')
      $('#progressStatus4 > path').attr('stroke', '#3a85fc')
      $('#progressStatus5').css('transform', 'translateX(-50%) scale(1)')
      $('#progressStatus5 > path').attr('stroke', '#D2D2D2')
    } else if (updatedProgressStatus < 80 && updatedProgressStatus > 60) {
      $('#progressStatus2').css('transform', 'translateX(-50%) scale(1.8)')
      $('#progressStatus2 > path').attr('stroke', '#3a85fc')
      $('#progressStatus3').css('transform', 'translateX(-50%) scale(1.8)')
      $('#progressStatus3 > path').attr('stroke', '#3a85fc')
      $('#progressStatus4').css('transform', 'translateX(-50%) scale(1.8)')
      $('#progressStatus4 > path').attr('stroke', '#3a85fc')
      $('#progressStatus5').css('transform', 'translateX(-50%) scale(1.8)')
      $('#progressStatus5 > path').attr('stroke', '#3a85fc')
    } else if (updatedProgressStatus < 100) {
      $('#progressStatus6 > path').attr('fill', '#D2D2D2')
    } else if (updatedProgressStatus >= 100) {
      $('#progressStatus2').css('transform', 'translateX(-50%) scale(1.8)')
      $('#progressStatus2 > path').attr('stroke', '#3a85fc')
      $('#progressStatus3').css('transform', 'translateX(-50%) scale(1.8)')
      $('#progressStatus3 > path').attr('stroke', '#3a85fc')
      $('#progressStatus4').css('transform', 'translateX(-50%) scale(1.8)')
      $('#progressStatus4 > path').attr('stroke', '#3a85fc')
      $('#progressStatus5').css('transform', 'translateX(-50%) scale(1.8)')
      $('#progressStatus5 > path').attr('stroke', '#3a85fc')
      $('#progressStatus6 > path').attr('fill', '#3a85fc')
    }

    lastScrollPosition = windowTop
  }
}
