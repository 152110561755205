import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  footerMenu = [
    { url: '/', name: 'Home' },
    { url: '/about-us', name: 'About Us' },
    { url: '/services', name: 'Our Services' },
    { url: '/products', name: 'Our Products' },
    { url: '/careers', name: 'Careers' },
  ]

  contactInfo = {
    phone: '(+95) 9 402821154',
    email: 'hello@xerovit.io',
    address: 'Room 202, Royal Towers, C8, Yaw Min Gyi Ward, Yangon.',
  }

  constructor() {}

  ngOnInit(): void {}
}
