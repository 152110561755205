<!----- Newsletter Section ----->
<div class="newsletter">
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3 col-sm-12">
        <h3 class="sub-title">Subscribe Our Newsletter</h3>
        <p>Stay in the Know with Our Newsletter.</p>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            placeholder="Enter Your Email Address"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
          />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button">
              SUBSCRIBE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!----- Footer Section ----->
<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div class="logo-title">
          <a href="#" routerLink="/"
            ><img src="assets/img/xerovit-logo-footer.svg" alt=""
          /></a>
        </div>
        <ul class="quick-link">
          <ng-container *ngFor="let item of footerMenu">
            <li>
              <a [routerLink]="item.url">{{ item.name }}</a>
            </li>
          </ng-container>
          <!-- <li><a href="#" routerLink="/about-us">About Us</a></li>
            <li><a href="#" routerLink="/services">Our Services</a></li>
            <li><a href="#" routerLink="/products">Our Products</a></li>
            <li><a href="#" routerLink="/careers">Careers</a></li> -->
          <!-- <li><a href="#">Blog</a></li> -->
          <!-- <li><a href="platform-overview.html" target="_blank">Platform Overview</a></li>
            <li><a href="coming-soon.html" target="_blank">News</a></li>
            <li><a href="coming-soon.html" target="_blank">Events</a></li> -->
          <!-- <li><a href="#" routerLink="/case-studies">Case Studies</a></li> -->
          <!-- <li><a href="404.html">FAQ</a></li> -->
          <!-- <li><a href="404.html">Privacy Policy</a></li> -->
          <!-- <li><a href="coming-soon.html" target="_blank">Careers</a></li> -->
          <!-- <li><a href="coming-soon.html" target="_blank">FAQ</a></li>
            <li><a href="coming-soon.html" target="_blank">Privacy Policy</a></li> -->
          <li><a href="#" routerLink="/contact-us">Get In Touch</a></li>
        </ul>
      </div>
      <div class="col-md-4">
        <div class="ft-title">GET IN TOUCH</div>
        <ul class="contact-info">
          <li>
            <p>PHONE NUMBER</p>
            <p>
              <a href="tel:(+95) 9 402821154">{{ contactInfo.phone }}</a>
            </p>
          </li>
          <li>
            <p>E-MAIL</p>
            <p>
              <a href="mailto:hello@xerovit.io">{{ contactInfo.email }}</a>
            </p>
          </li>
          <li>
            <p>Address</p>
            <p>{{ contactInfo.address }}</p>
          </li>
        </ul>
      </div>
      <div class="col-md-4">
        <div class="ft-title">FOLLOW US</div>
        <ul>
          <li>
            <p>
              Discover how you can embrace innovation to drive new value for
              your organization. Explore new insights. See tangible outcomes.
            </p>
          </li>
          <li class="social">
            <a href="http://facebook.com/xerovit.io" target="_blank"
              ><i class="fa fa-facebook" aria-hidden="true"></i
            ></a>
            <a href="https://www.linkedin.com/company/xerovit/" target="_blank"
              ><i class="fa fa-linkedin" aria-hidden="true"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="copyright">
  <p>Copyright © 2020 Xerovit</p>
</div>
