import { Component, OnInit } from '@angular/core'

declare function CaseStudiesEffect(): any
declare var $: any

@Component({
  selector: 'app-case-studies',
  templateUrl: './case-studies.component.html',
  styleUrls: ['./case-studies.component.scss'],
})
export class CaseStudiesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    window.scroll(0, 0)

    CaseStudiesEffect()
  }
}
