import { Component, OnInit } from '@angular/core'

declare function ContactEffect(): any
declare var $: any

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss'],
})
export class ContactPageComponent implements OnInit {
  contactInfo = {
    email: 'hello@xerovit.io',
    phone: '+ (95) 9 40282 1154',
    address: 'Room 202, Royal Towers, C8, Yaw Min Gyi Ward, Yangon.',
  }

  constructor() {}

  ngOnInit(): void {
    window.scroll(0, 0)

    ContactEffect()
  }
}
