<div class="header">
  <div class="cnt-header contact-box">
    <div class="container">
      <!----- Page Title ----->
      <div class="row">
        <div class="col-md-12 text-center">
          <h1>Contact Us</h1>
        </div>
      </div>
      <div class="row mt-4">
        <!----- Contact Text ----->
        <div class="offset-md-1 col-md-4 d-flex align-items-center">
          <div class="contact-txt">
            <h3>We'd love to hear from you!</h3>
            <p class="mt-5 mb-5">
              Who we are today is the result of choices we made yesterday.
              Tomorrow we will become what we choose today. To change means to
              choose to change.
            </p>
            <p>
              <b
                >Talk to us today to change and transform your business through
                digital innovation.</b
              >
            </p>
          </div>
        </div>
        <!----- Contact form ----->
        <div class="col-md-6">
          <div class="contact-form">
            <form>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="formGroupExampleInput"
                  placeholder="Your name"
                />
              </div>
              <div class="form-group">
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Email"
                />
              </div>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="formGroupExampleInput"
                  placeholder="Work phone"
                />
              </div>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="formGroupExampleInput"
                  placeholder="Company name"
                />
              </div>
              <div class="form-group">
                <select class="form-control" id="exampleFormControlSelect1">
                  <option value="">Select country</option>
                  <option value="">Myanmar</option>
                  <option value="">Singapore</option>
                  <option value="">Houn Kong</option>
                  <option value="">Philippines</option>
                  <option value="">Thailand</option>
                  <option value="">Japan</option>
                  <option value="">Other</option>
                </select>
              </div>
              <div class="form-group">
                <select class="form-control" id="exampleFormControlSelect1">
                  <option value="">I am interested in</option>
                  <option value="">Digital Transformation Consultation</option>
                  <option value="">Digital Solutions & Services</option>
                  <option value="">Online Marketing</option>
                  <option value="">Dedicated Outsourcing</option>
                  <option value="">Idea Kickstarter Package</option>
                  <option value="">Other</option>
                </select>
              </div>

              <div class="form-group">
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  placeholder="Message . . ."
                ></textarea>
              </div>
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                />
                <label class="form-check-label" for="exampleCheck1"
                  >Send NDA</label
                >

                <div class="image-upload float-right">
                  <label for="file-input"
                    ><img src="assets/img/attachment.png"
                  /></label>
                  <input id="file-input" type="file" />Attach file
                </div>
              </div>
              <button type="submit" class="effect-btn btn-blue col-12">
                TALK TO OUR TEAM
              </button>
              <p class="mt-3">
                By sending this form | confirm that | have read and accept
                <a href="/terms-conditions" target="_blank"
                  >terms & conditions</a
                >
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!----- Contact Info ----->
<div class="contact-info">
  <div class="d-flex flex-row align-self-stretch">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">
          <img src="assets/img/mail-icon.png" alt="" /> EMAIL ADDRESS
        </h4>
        <p class="card-text">
          <a href="mailto:hello@xerovit.io">{{ contactInfo.email }}</a>
        </p>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">
          <img src="assets/img/phone-icon.png" alt="" /> PHONE NUMBER
        </h4>
        <p class="card-text">
          <a href="tel:+ (95) 9 40282 1154">{{ contactInfo.phone }}</a>
        </p>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">
          <img src="assets/img/address-icon.png" alt="" /> ADDRESS
        </h4>
        <p class="card-text">{{ contactInfo.address }}</p>
      </div>
    </div>
  </div>
</div>

<!----- Visit Text ----->
<div class="visit-txt">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h3>Visit our office. Find your way.</h3>
        <p>Click the map to begin exploring.</p>
      </div>
    </div>
  </div>
</div>

<!----- Contact Map ----->
<div class="cnt-map">
  <!-- <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1c2N7xTkdkyF_xH64MgeHa0P6I-BILkr_" width="100%"
        height="450"></iframe> -->
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3820.01201383021!2d96.17004301450456!3d16.776077888447656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30c1ed0ec1b1193d%3A0x98b75a1b5261345b!2sXerovit!5e0!3m2!1sen!2smm!4v1581327059704!5m2!1sen!2smm"
    width="100%"
    height="450"
    frameborder="0"
    style="border: 0;"
    allowfullscreen=""
  ></iframe>

  <!-- <div id="map" style="width: 100%; height: 450px;"></div> -->
</div>
