import { Component, HostListener } from '@angular/core'

declare function LogoSlider(): any
declare function HomeEffect(): any
declare var $: any

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent {
  colorOptions: any = ['#3a85fc', '#00EAC3', '#3a85fc', '#2b47a3']
  // clientLogos = [
  //   'assets/img/client-logo/cas.png',
  //   'assets/img/client-logo/crew8.png',
  //   'assets/img/client-logo/doornlock.png',
  //   'assets/img/client-logo/impacthub.png',
  //   'assets/img/client-logo/nomad.png',
  //   'assets/img/client-logo/preciselifescience.png',
  //   'assets/img/client-logo/rbca.png',
  //   'assets/img/client-logo/vsa.png'
  // ];

  // clientLogos = [
  //   {id: 'img1', logo: 'assets/img/client-logo/cas.png'},
  //   {id: '#img2', logo: 'assets/img/client-logo/crew8.png'},
  //   {id: '#img3', logo: 'assets/img/client-logo/doornlock.png'},
  //   {id: 'img4', logo: 'assets/img/client-logo/impacthub.png'},
  //   {id: '#img5', logo: 'assets/img/client-logo/nomad.png'},
  //   {id: '#img6', logo: 'assets/img/client-logo/preciselifescience.png'},
  //   {id: '#img7', logo: 'assets/img/client-logo/rbca.png'},
  //   {id: '#img8', logo: 'assets/img/client-logo/vsa.png'},
  // ];

  // test = [{id:'', image:''}, {id:'', image:''}, ]

  constructor() {
    LogoSlider()
  }

  //seleton-loader
  loaded = true

  ngOnInit() {
    //seleton-loader
    // setInterval(() => {
    //   this.loaded = true;
    // });

    window.scroll(0, 0)
    HomeEffect()

    $('#images-wrapper').css('background-color', this.colorOptions[0])
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    var lastScrollTop = 0
    var windowWidth = $(window).width()
    if (windowWidth > 769) {
      var windowTop = $(window).scrollTop()

      var windowBottom = $(window).scrollTop() + $(window).height()

      var scrollSterTop = $('#text-image-scroll').position().top
      var scrollSterBottom =
        $('#text-image-scroll').position().top +
        $('#text-image-scroll').outerHeight(true)
      var textWrapperHeight = $('#text-wrapper').height()

      var centerImage1 = $('#center-image1').position().left
      var centerImage2 = $('#center-image2').position().left

      if (centerImage1 > windowWidth / 5) {
        $('#images-wrapper').css('background-color', this.colorOptions[0])
      } else if (
        centerImage1 < windowWidth / 5 &&
        centerImage2 > windowWidth / 5
      ) {
        $('#images-wrapper').css('background-color', this.colorOptions[1])
      } else if (
        centerImage2 < windowWidth / 5 &&
        centerImage2 > -Math.abs(windowWidth / 5)
      ) {
        $('#images-wrapper').css('background-color', this.colorOptions[2])
      } else if (centerImage2 < -Math.abs(windowWidth / 5)) {
        $('#images-wrapper').css('background-color', this.colorOptions[3])
      }

      if (windowTop > scrollSterTop) {
        $('#images-wrapper').addClass('images-wrapper-fixed')
      } else {
        $('#images-wrapper').removeClass('images-wrapper-fixed')
      }

      if (windowBottom > scrollSterTop + textWrapperHeight / 17) {
        $('#images-wrapper').addClass('images-wrapper-open')
      } else {
        $('#images-wrapper').removeClass('images-wrapper-open')
        $('#images-wrapper .images img').removeClass('images-show')
      }
      if (windowBottom > scrollSterTop + textWrapperHeight / 10) {
        $('#images-wrapper .images img').addClass('images-show')
      } else {
        $('#images-wrapper .images img').removeClass('images-show')
      }

      if (windowBottom > scrollSterBottom) {
        $('#images-wrapper').removeClass('images-wrapper-fixed')
        $('#images-wrapper').addClass('images-wrapper-fixed-stop')
      } else {
        $('#images-wrapper').removeClass('images-wrapper-fixed-stop')
      }

      if (
        $('#images-wrapper').hasClass('images-wrapper-fixed') &&
        windowBottom < scrollSterBottom - 100
      ) {
        var imageWrapperWidth = $('#images-wrapper').width() * 4

        var scrolled = windowTop - scrollSterTop
        var percScrolledOfScrollSter = scrolled / textWrapperHeight
        var pxOfImageWrapper = imageWrapperWidth * percScrolledOfScrollSter
        var minus = Math.abs(pxOfImageWrapper) * -1
        $('.images').css('left', minus)
      }
      lastScrollTop = windowTop
    }
  }
}
