<div class="header header-bg2">
  <div class="cnt-header service-box">
    <div class="container">
      <!----- Head Section ----->
      <div class="row">
        <div class="col-md-12 text-center">
          <h1>Our Services</h1>
        </div>
      </div>
      <!----- Services List ----->
      <div class="row">
        <div class="col-lg-4 col-md-6 mt-3">
          <div class="card">
            <div class="card-body">
              <p class="card-text">
                <br />The successful exploitation of new ideas is crucial to a
                business being able to improve its processes, bring new and
                improved products and services to market, increase its
                efficiency and, most importantly, improve its profitability.
                Adapt to new challenges by start embracing changes today.Be
                Disruptive. Be Bold. Be Innovative.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 mt-4">
          <div
            class="card shadow"
            style="background-color: #fff; color: #4b4b4b;"
          >
            <!-- <app-seleton-loader *ngIf="!loaded" Cwidth="100" Cheight="100"></app-seleton-loader> -->
            <img
              *ngIf="loaded"
              class="card-img-top"
              src="assets/img/digital-transformation-consultation.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h5 class="card-title">Digital Transformation Consultation</h5>
              <p class="card-text">
                Together, let's apply innovation to unlock trapped value within
                your organization, helping the business embrace disruption and
                transform to lead in the future.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 mt-4">
          <div class="card shadow">
            <!-- <app-seleton-loader *ngIf="!loaded" Cwidth="100" Cheight="100"></app-seleton-loader> -->
            <img
              *ngIf="loaded"
              class="card-img-top"
              src="assets/img/digital-solutions-services.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h5 class="card-title">Digital Solutions & Services</h5>
              <p class="card-text">
                We look beyond core business and think differently about
                innovation, and we will beautifully designed digital solutions
                that are tailored to your business needs.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 mt-4">
          <div class="card shadow">
            <!-- <app-seleton-loader *ngIf="!loaded" Cwidth="100" Cheight="100"></app-seleton-loader> -->
            <img
              *ngIf="loaded"
              class="card-img-top"
              src="assets/img/online-marketing.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h5 class="card-title">Online Marketing</h5>
              <p class="card-text">
                From consulting and creative campaigns to insights and
                analytics, we provide solutions to build your brand and drive
                demand through high-impact, creative customer experiences.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 mt-4">
          <div class="card shadow">
            <!-- <app-seleton-loader *ngIf="!loaded" Cwidth="100" Cheight="100"></app-seleton-loader> -->
            <img
              *ngIf="loaded"
              class="card-img-top"
              src="assets/img/dedicated-outsourcing.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h5 class="card-title">Dedicated Outsourcing</h5>
              <p class="card-text">
                A business idea might be innovative, but it’s worth nothing if
                there’s nobody on hand to make it work. Dedicated outsourcing,
                is the key to innovation by solving capacity issues, creating
                global scalability, and providing access to intellectual
                capital.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 mt-4">
          <div class="card shadow">
            <!-- <app-seleton-loader *ngIf="!loaded" Cwidth="100" Cheight="100"></app-seleton-loader> -->
            <img
              *ngIf="loaded"
              class="card-img-top"
              src="assets/img/idea-kickstarter-package.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h5 class="card-title">Idea Kickstarter Package</h5>
              <p class="card-text">
                High Quality MVP Solution with Cost & Time predictability. We
                assist startups to kickstart their idea through Proof of
                Concepts & Minimum Viable Products with a dedicated product
                development team.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
